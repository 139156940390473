import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { setMessage } from './messageSlice';
import axios from '../../utils/axios';

export const fetchSortType = createAsyncThunk('sort/SortType', async () => {
  try {
    const { data } = await axios.get('/type/getAll');
    return data;
  } catch (err) {
    console.log(err);
  }
});
export const fetchCreateType = createAsyncThunk('sort/CreateType', async (params, thunkAPI) => {
  try {
    const { data } = await axios.post('/type/createType', params);
    thunkAPI.dispatch(setMessage(data.message));

    return data;
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});
export const fetchDeleteType = createAsyncThunk('sort/DeleteType', async ({ id }, thunkAPI) => {
  try {
    const { data } = await axios.delete('/type/deleteType/', { data: { id } });

    thunkAPI.dispatch(setMessage(data.message));
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});
export const fetchUpdateType = createAsyncThunk('sort/UpdateType', async (params, thunkAPI) => {
  try {
    const { data } = await axios.put('/type/updateType/', params);
    thunkAPI.dispatch(setMessage(data.message));
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const fetchSortCategory = createAsyncThunk('sort/SortCategory', async () => {
  try {
    const { data } = await axios.get('/category/getAll');
    return data;
  } catch (e) {
    console.log(e);
  }
});
export const fetchCreateCategory = createAsyncThunk(
  'sort/CreateCategory',
  async (params, thunkAPI) => {
    try {
      const { data } = await axios.post('/Category/createCategory', params);
      thunkAPI.dispatch(setMessage(data.message));

      return data;
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);
export const fetchDeleteCategory = createAsyncThunk(
  'sort/DeleteCategory',
  async ({ id }, thunkAPI) => {
    try {
      const { data } = await axios.delete('/Category/deleteCategory/', { data: { id } });

      thunkAPI.dispatch(setMessage(data.message));
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);
export const fetchUpdateCategory = createAsyncThunk(
  'sort/UpdateCategory',
  async (params, thunkAPI) => {
    try {
      const { data } = await axios.put('/Category/updateCategory/', params);
      thunkAPI.dispatch(setMessage(data.message));
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const fetchSortSubcategory = createAsyncThunk('sort/SortSubcategory', async () => {
  try {
    const { data } = await axios.get('/subcategory/getAll');
    return data;
  } catch (e) {
    console.log(e);
  }
});
export const fetchCreateSubcategory = createAsyncThunk(
  'sort/CreateSubcategory',
  async (params, thunkAPI) => {
    try {
      const { data } = await axios.post('/subcategory/createSubcategory', params);
      thunkAPI.dispatch(setMessage(data.message));

      return data;
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);
export const fetchDeleteSubcategory = createAsyncThunk(
  'sort/DeleteSubcategory',
  async ({ id }, thunkAPI) => {
    try {
      const { data } = await axios.delete('/subcategory/deleteSubcategory/', { data: { id } });

      thunkAPI.dispatch(setMessage(data.message));
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);
export const fetchUpdateSubcategory = createAsyncThunk(
  'sort/UpdateTSubcategory',
  async (params, thunkAPI) => {
    try {
      const { data } = await axios.put('/subcategory/updateSubcategory/', params);
      thunkAPI.dispatch(setMessage(data.message));
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

const initialState = {
  type: [],
  category: [],
  subcategory: [],
  status: 'loading',
};

const sortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSortType.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.type = action.payload.types;
    });

    builder.addCase(fetchSortCategory.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.category = action.payload.categories;
    });

    builder.addCase(fetchSortSubcategory.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.subcategory = action.payload.categories;
    });

    // CRUD=============================================================
    builder.addCase(fetchCreateType.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.type.push(action.payload.type);
    });
  },
});
export const sortReducer = sortSlice.reducer;
