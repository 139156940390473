import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import InfoUser from '../components/Lk/InfoUser';
import MyBuy from '../components/Lk/MyBuy';
import MyComments from '../components/Lk/MyComments';
import MenuNavigation from '../components/MenuNavigation';
import { selectIsAuth } from '../redux/slice/userSlice';

function Lk() {
  const { data } = useSelector((state) => state.auth);
  const isAuth = useSelector(selectIsAuth);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isAuth && !window.localStorage.getItem('token')) {
      navigate('/login');
    }
  }, [isAuth, navigate]);

  if (!data) {
    return '';
  }
  return (
    <div className="lk">
      <h1 className="lk__title">личный кабинет</h1>
      <div className="row">
        <div className="col-lg-3">
          <MenuNavigation />
        </div>
        <div className="col-lg-9">
          <InfoUser userInfo={data.user} />
          <MyBuy />
          <MyComments />
        </div>
      </div>
    </div>
  );
}

export default Lk;
