import React from 'react';
import { Link } from 'react-router-dom';

function MenuHeader({ status }) {
  const style = {
    overflow: 'hidden',
    display: status ? 'block' : 'none',
    transition: '.3s linear',
  };

  return (
    <div className="menu hide-content col-8" style={style}>
      <h3>МЕНЮ</h3>
      <div className="row">
        <div className="col-lg-3 col-sm-6">
          <div>
            <h4>
              <Link>Курсы</Link>
            </h4>
            <ul>
              <li>
                <Link>Майноры</Link>
              </li>
              <li>
                <Link>Программы</Link>
              </li>
              <li>
                <Link>ДПО</Link>
              </li>
            </ul>
          </div>
          <div>
            <h4>
              <Link>Мерч</Link>
            </h4>
            <ul>
              <li>
                <Link>Коллекции</Link>
              </li>
              <li className="content-block">
                <span className="show-more">
                  <Link>Одежда</Link>
                </span>
                <div className="hide-content">
                  <ul className="ul-circle">
                    <li>
                      <Link to="">толстовка</Link>
                    </li>
                    <li>
                      <Link to="">свитшот</Link>
                    </li>
                    <li>
                      <Link>поло</Link>
                    </li>
                    <li>
                      <Link>футболка</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="content-block">
                <h4 className="show-more">
                  <Link>Аксессуары</Link>
                </h4>
                <div className="hide-content">
                  <ul className="ul-circle">
                    <li>
                      <Link>термокружка</Link>
                    </li>
                    <li>
                      <Link>спортивная бутылка для воды</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link>Канцелярия</Link>
              </li>
              <li>
                <Link>Сертификаты</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div>
            <h4>
              <Link>Цифровые товары</Link>
            </h4>
            <ul>
              <li>
                <Link>Заставки</Link>
              </li>
              <li>
                <Link>Календарь</Link>
              </li>
              <li>
                <Link>Песня про ВИВТ</Link>
              </li>
            </ul>
          </div>
          <div>
            <h4>
              <Link>О магазине</Link>
            </h4>
            <ul>
              <li>
                <Link>Доставка и оплата</Link>
              </li>
              <li>
                <Link>Акции</Link>
              </li>
              <li>
                <Link>Контакты</Link>
              </li>
            </ul>
          </div>
          <h4>
            <Link>Сайт ВИВТ</Link>
          </h4>
          <h4>
            <Link>Сайт колледж ВИВТ</Link>
          </h4>
        </div>
      </div>
    </div>
  );
}

export default MenuHeader;
