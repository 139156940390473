import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import axios from '../utils/axios';
import MenuNavigation from '../components/MenuNavigation';
import ProductBlock from '../components/Products/ProductBlock';
import { selectIsAuth } from '../redux/slice/userSlice';
import ProductEmpty from '../components/Products/ProductEmpty';

function MyFavorites() {
  const [data, setData] = React.useState();

  const isAuth = useSelector(selectIsAuth);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isAuth && !window.localStorage.getItem('token')) {
      navigate('/login');
    }
  }, [isAuth, navigate]);

  React.useEffect(() => {
    axios
      .get(`/favourites/getAll`)
      .then(({ data }) => {
        setData(data.favorite);
      })
      .catch((err) => {
        console.warn(err);
        alert('Произошла ошибка');
      });
  }, []);

  if (!data) {
    return '';
  }

  return (
    <div className="lk">
      {console.log(data)}
      <h1 className="lk__title">Мои желания</h1>
      <div className="row">
        <div className="col-lg-3">
          <MenuNavigation />
        </div>
        <div className="col-lg-9">
          <ul class="cards">
            {data ? (
              data.map((item) => (
                <li>
                  <ProductBlock
                    name={item.name}
                    description={item.description}
                    price={item.price}
                    imageUrl={item.imgUrl}
                    id={item._id}
                    key={item._id}
                  />
                </li>
              ))
            ) : (
              <ProductEmpty />
            )}
          </ul>

          {/* <div className="row">
            {data
              ? data.map((item) => (
                  <ProductBlock
                    title={item.name}
                    description={item.description}
                    price={item.price}
                    imageUrl={item.imgUrl}
                    id={item._id}
                    key={item._id}
                  />
                ))
              : ''}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default MyFavorites;
