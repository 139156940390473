import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { setMessage } from '../../redux/slice/messageSlice';
import {
  fetchCreateCategory,
  fetchCreateSubcategory,
  fetchCreateType,
  fetchDeleteCategory,
  fetchDeleteSubcategory,
  fetchDeleteType,
  fetchSortCategory,
  fetchSortSubcategory,
  fetchSortType,
  fetchUpdateCategory,
  fetchUpdateSubcategory,
  fetchUpdateType,
} from '../../redux/slice/sortSlice';

function ManagementFilter() {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  const { type, category, subcategory } = useSelector((state) => state.sort);

  const [state, setState] = React.useState({
    type: {
      id: '',
      title: '',
    },
    category: {
      id: '',
      title: '',
    },
    subcategory: {
      id: '',
      title: '',
    },
  });

  React.useEffect(() => {
    dispatch(fetchSortType());
    dispatch(fetchSortCategory());
    dispatch(fetchSortSubcategory());

    message && toast.info(message);
    message && dispatch(setMessage(''));
  }, [dispatch, message]);

  if (!type || !category || !subcategory) {
    return 'Загрузка....';
  }

  return (
    <div className="management-filter">
      <div className="col-3">
        <label>Тип товара</label>
        <div>
          <input
            type="text"
            value={state.type.title}
            onChange={(e) => setState({ ...state, type: { ...state.type, title: e.target.value } })}
          />
          {state.type.title && (
            <button
              className="management-filter__button--update"
              onClick={() => {
                dispatch(fetchCreateType(state.type));
                setState({ ...state, type: { ...state.type, title: '' } });
              }}>
              Добавить
            </button>
          )}
        </div>
        <select
          onChange={(e) =>
            setState({
              ...state,
              type: {
                ...state.type,
                id: e.target.value,
                title: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
              },
            })
          }>
          <option defaultValue></option>
          {type.map((type) => (
            <option key={type._id} value={type._id}>
              {type.title}
            </option>
          ))}
        </select>
        <div>
          <button
            className="management-filter__button--remove"
            onClick={() => {
              dispatch(fetchDeleteType(state.type));
              setState({ ...state, type: { ...state.type, title: '' } });
            }}>
            Удалить
          </button>
          <button
            className="management-filter__button--update"
            onClick={() => dispatch(fetchUpdateType(state.type))}>
            Изменить
          </button>
        </div>
      </div>

      <div className="col-3">
        <label>Категория товара</label>
        <div>
          <input
            type="text"
            value={state.category.title}
            onChange={(e) =>
              setState({ ...state, category: { ...state.category, title: e.target.value } })
            }
          />
          {state.category.title && (
            <button
              className="management-filter__button--update"
              onClick={() => {
                dispatch(fetchCreateCategory(state.category));
                setState({ ...state, category: { ...state.category, title: '' } });
              }}>
              Добавить
            </button>
          )}
        </div>
        <select
          onChange={(e) =>
            setState({
              ...state,
              category: {
                ...state.category,
                id: e.target.value,
                title: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
              },
            })
          }>
          <option defaultValue></option>
          {category.map((category) => (
            <option key={category._id} value={category._id}>
              {category.title}
            </option>
          ))}
        </select>
        <div>
          <button
            className="management-filter__button--remove"
            onClick={() => {
              dispatch(fetchDeleteCategory(state.category));
              setState({ ...state, category: { ...state.category, title: '' } });
            }}>
            Удалить
          </button>
          <button
            className="management-filter__button--update"
            onClick={() => dispatch(fetchUpdateCategory(state.category))}>
            Изменить
          </button>
        </div>
      </div>

      <div className="col-3">
        <label>Подкатегория товара</label>
        <div>
          <input
            type="text"
            value={state.subcategory.title}
            onChange={(e) =>
              setState({ ...state, subcategory: { ...state.subcategory, title: e.target.value } })
            }
          />
          {state.subcategory.title && (
            <button
              className="management-filter__button--update"
              onClick={() => {
                dispatch(fetchCreateSubcategory(state.subcategory));
                setState({ ...state, category: { ...state.subcategory, title: '' } });
              }}>
              Добавить
            </button>
          )}
        </div>
        <select
          onChange={(e) =>
            setState({
              ...state,
              subcategory: {
                ...state.subcategory,
                id: e.target.value,
                title: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
              },
            })
          }>
          <option defaultValue></option>
          {subcategory.map((subcategory) => (
            <option key={subcategory._id} value={subcategory._id}>
              {subcategory.title}
            </option>
          ))}
        </select>
        <div>
          <button
            className="management-filter__button--remove"
            onClick={() => {
              dispatch(fetchDeleteSubcategory(state.subcategory));
              setState({ ...state, subcategory: { ...state.subcategory, title: '' } });
            }}>
            Удалить
          </button>
          <button
            className="management-filter__button--update"
            onClick={() => dispatch(fetchUpdateSubcategory(state.subcategory))}>
            Изменить
          </button>
        </div>
      </div>
    </div>
  );
}

export default ManagementFilter;
