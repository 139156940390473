import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { addItem } from '../../redux/slice/cartSlice';

function ProductBlock({ id, name, description, price, imageUrl }) {
  const dispatch = useDispatch();

  const [selectParams, setSelectParams] = React.useState({
    sizes: 'default',
    color: 'default',
  });

  const onClickAdd = () => {
    const item = {
      id,
      name,
      price,
      imageUrl,
      selectParams,
    };
    dispatch(addItem(item));
  };

  return (
    <div className="product-card">
      <Link to={`/product/${id}`}>
        <img
          src={`https://shop.vivt.ru/api/uploads/${imageUrl}`}
          className="product-card__image"
          alt=""
        />
      </Link>

      <div className="product-card__overlay">
        <div className="product-card__header">
          <svg className="product-card__arc" xmlns="http://www.w3.org/2000/svg">
            <path></path>
          </svg>

          <div className="product-card__header-text">
            <h3 className="product-card__title">{name}</h3>
          </div>
        </div>

        <div className="product-card__thumb">
          <p className="product-card__description">{description.substring(0, 100)}...</p>
          <p className="product-card__price">{price} ₽</p>
          <button onClick={onClickAdd}>Добавить в корзину</button>
        </div>
      </div>
    </div>

    // <div className="col-md-4 col-sm-6">
    //   <div className="product">
    //     <div className="product__top">
    //       <img src={`http://localhost:5000/api/uploads/${imageUrl}`} alt="" />
    //     </div>

    //     <div className="product__bottom">
    //       <Link to={`/product/${id}`}>
    //         <div className="product__title row">
    //           <h2 className="col-md-8">{name}</h2>
    //           <h2 className="product__price col-md-4">{price} ₽</h2>
    //         </div>
    //       </Link>
    //       <div className="product__description">
    //         <p>{description}</p>
    //       </div>

    //       <div className="product__button row">
    //         <div className="col-12">
    //           <button onClick={onClickAdd}>Добавить в корзину</button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default ProductBlock;
