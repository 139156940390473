import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';

function ProductImages({ mainImage, imageList }) {
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);

  return (
    <div className="images">
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="images__main">
        <SwiperSlide>
          <img src={`https://shop.vivt.ru/api/uploads/${mainImage}`} alt="vivt shop" />
        </SwiperSlide>
        {imageList.map((image) => (
          <SwiperSlide key={image}>
            <img src={`https://shop.vivt.ru/api/uploads/${image}`} alt="vivt shop" />
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="images__list">
        <SwiperSlide>
          <img src={`https://shop.vivt.ru/api/uploads/${mainImage}`} alt="vivt shop" />
        </SwiperSlide>
        {imageList.map((image) => (
          <SwiperSlide key={image}>
            <img src={`https://shop.vivt.ru/api/uploads/${image}`} alt="vivt shop" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default ProductImages;
