import React from 'react';
import { useParams } from 'react-router-dom';

import axios from '../utils/axios';
import ProductBlock from '../components/Products/ProductBlock';
import MenuNavigation from '../components/MenuNavigation';
import ProductEmpty from '../components/Products/ProductEmpty';

function Collection() {
  const [data, setData] = React.useState();

  const { id } = useParams();

  React.useEffect(() => {
    axios.get(`/collection/getId/${id}`).then(({ data }) => {
      setData(data);
    });
  }, []);

  if (!data) {
    return '';
  }

  return (
    <section className="home">
      {console.log(data)}
      <div className="home__container row">
        <div className="col-lg-3">
          <MenuNavigation />
        </div>
        <div className="col-lg-9 row">
          <div>
            <h1>{data.name}</h1>

            <img
              src={`https://shop.vivt.ru/api/uploads/${data.preview}`}
              alt=""
              width="100%"
              height="200px"
            />
          </div>
          <div className="col-lg-9 ">
            <ul class="cards">
              {data.products ? (
                data.products.map((item) => (
                  <li>
                    <ProductBlock
                      name={item.name}
                      description={item.description}
                      price={item.price}
                      imageUrl={item.imgUrl}
                      id={item._id}
                      key={item._id}
                    />
                  </li>
                ))
              ) : (
                <ProductEmpty />
              )}
            </ul>
          </div>
          {/* {data.products.map((product) => (
            <ProductBlock
              id={product._id}
              name={product.name}
              description={product.description}
              price={product.price}
              imageUrl={product.imgUrl}
            />
          ))} */}
        </div>
      </div>
    </section>
  );
}

export default Collection;
