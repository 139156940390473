import React from 'react';
import { useParams } from 'react-router-dom';

import axios from '../utils/axios';
import ProductImages from '../components/FullProduct/ProductImages';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import ProductInfo from '../components/FullProduct/ProductInfo';

function FullProduct() {
  const [data, setData] = React.useState();

  const { id } = useParams();

  React.useEffect(() => {
    axios
      .get(`/get/${id}`)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        console.warn(err);
        alert('Произошла ошибка');
      });
  }, [id]);

  if (!data) {
    return '';
  }
  return (
    <section className="itemMain">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <ProductImages mainImage={data.imgUrl} imageList={data.specification.images} />
          </div>
          <div className="col-md-6">
            <ProductInfo
              id={data._id}
              name={data.name}
              price={data.price}
              imageUrl={data.imageUrl}
              description={data.description}
              sizes={data.specification.sizes}
              selectSize={data.specification.selectSize}
              colors={data.specification.colors}
              materials={data.specification.materials}
              isEdit={false}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FullProduct;
