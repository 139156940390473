import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setMessage } from './messageSlice';
import axios from '../../utils/axios';

export const fetchAddProduct = createAsyncThunk(
  'product/addProduct',
  async ({ infoProduct, stateImagesIndex }) => {
    try {
      const { data } = await axios.post('/createProduct', { infoProduct, stateImagesIndex });
      return data;
    } catch (err) {}
  },
);

const initialState = {
  data: null,
  status: 'loading',
  isLoading: false,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    updateIsLoading: (state) => {
      state.isLoading = true;
    },
    reIsLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {},
});

export const productReducer = productSlice.reducer;
export const { updateIsLoading, reIsLoading } = productSlice.actions;
