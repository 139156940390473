import React from 'react';
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles

// import required modules
import { Scrollbar } from 'swiper';

import axios from '../utils/axios';
import 'swiper/css';
import 'swiper/css/scrollbar';

import lookbook_1 from '../assets/img/test/lookbook/1.png';
import lookbook_2 from '../assets/img/test/lookbook/2.png';
import lookbook_3 from '../assets/img/test/lookbook/3.png';
import lookbook_4 from '../assets/img/test/lookbook/4.png';
import lookbook_5 from '../assets/img/test/lookbook/5.png';
import lookbook_6 from '../assets/img/test/lookbook/6.png';

function Merch() {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    axios.get('/collection/getAll').then(({ data }) => {
      setData(data);
    });
  }, []);

  if (!data) {
    return '';
  }
  return (
    <>
      <section class="welcome-banner-merch">
        <div class="container">
          <div class="col-6 mx-auto welcome-banner-merch__text">
            <h1>Мерч</h1>
            <p>
              В Воронежском институте высоких технологий открыт интернет-магазин товаров с
              корпоративной символикой! Студенты и преподаватели Йеля, Гарварда, Оксфорда, Кембриджа
              и многие другие с гордостью носят одежду и пользуются аксессуарами с символикой Alma
              mater. Присоединяйтесь и Вы к многолетней традиции студенческого братства!
            </p>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-3">
              <div class="catalog">
                <div>
                  <h1>Каталог</h1>
                  <ul>
                    <li>Коллекции</li>
                    <li>
                      <Link to="/products">одежда</Link>
                    </li>
                    <li>Аксессуары</li>
                    <li>Канцелярия</li>
                    <li>Сертификаты</li>
                    <li>Бонус-песня</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-9">
              <Swiper
                slidesPerView={3}
                spaceBetween={10}
                scrollbar={{
                  hide: true,
                }}
                modules={[Scrollbar]}
                className="mySwiper swiper-collections">
                {data.map((element) => (
                  <SwiperSlide>
                    <Link to={`../../collection/${element._id}`}>
                      {' '}
                      <div class="collection">
                        <img
                          src={`https://shop.vivt.ru/api/uploads/${element.preview}`}
                          alt=""
                          width="100%"
                        />
                        <h1>{element.name}</h1>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section class="look-book">
        <div class="container">
          <div class="mx-auto col-7 look-book__text">
            <h1>lookbook</h1>
            <p>
              В качестве моделей для всех материалов мы всегда снимаем наших студентов! В данном
              разделе вы можете посмотреть кадры с последней фотосьемки. Если вы заинтересованы в
              сотрудничестве,то пришлите на почту reklama@vivt.ru свое портфолио работ в качестве
              модели или предметного фотографа и мы с вами обязательно свяжемся!
            </p>
          </div>
          <div class="col-12 look-book__image">
            <div class="col-12">
              <img src={lookbook_1} class="look-book__image--banner" alt="" />
            </div>
            <div class="look-book__body">
              <div class="col-6 look-book__left">
                <div class="col-12">
                  <img src={lookbook_2} alt="" />
                </div>
              </div>
              <div class="row col-md-6 look-book__right">
                <div class="col-6">
                  <img src={lookbook_3} alt="" />
                </div>
                <div class="col-6">
                  <img src={lookbook_4} alt="" />
                </div>
                <div class="col-6">
                  <img src={lookbook_5} alt="" />
                </div>
                <div class="col-6">
                  <img src={lookbook_6} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="reviews">
        <div class="container">
          <h1>Отзывы</h1>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4">
                  <img src="../assets/img/test/reviews/item.png" alt="" />
                </div>
                <div class="col-md-8">
                  <p>
                    «Хочу сказать огромное спасибо команде МИФа, спикерам и всем кто принимал
                    участие в помощи принять и полюбить себя! Вы научили нас работать с различными
                    практиками, которые помогают увидеть саму суть, докопаться до истины и узнать
                    где здесь мое, а где чужое (это про чужие установки)! Этот курс научил меня
                    искать и находить себя, про себя и для себя! Научил ценить что имеем и любить
                    здесь и сейчас, относится с заботой к своему телу, мыслям, желаниям!»
                  </p>
                  <p>Ольга Лукьянова</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4">
                  <img src="../assets/img/test/reviews/item.png" alt="" />
                </div>
                <div class="col-md-8">
                  <p>
                    «Хочу сказать огромное спасибо команде МИФа, спикерам и всем кто принимал
                    участие в помощи принять и полюбить себя! Вы научили нас работать с различными
                    практиками, которые помогают увидеть саму суть, докопаться до истины и узнать
                    где здесь мое, а где чужое (это про чужие установки)! Этот курс научил меня
                    искать и находить себя, про себя и для себя! Научил ценить что имеем и любить
                    здесь и сейчас, относится с заботой к своему телу, мыслям, желаниям!»
                  </p>
                  <p>Ольга Лукьянова</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Merch;
