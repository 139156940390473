import React from 'react';

import userBaseImg from '../../assets/img/UserBaseImg.png';
function OrderList({ order }) {
  const [hidden, setHidden] = React.useState(false);

  const styleColor = {
    color: !order.processed ? '#39B782' : 'red',
  };
  const styleBackground = {
    background: !order.processed ? '#39B782' : 'red',
  };
  const handleOrder = () => {
    setHidden(!hidden);
  };
  return (
    <div className=" col-12">
      <div className="order-item " onClick={handleOrder}>
        <div className="order-item__container">
          <div className="order-item__id">{order._id}</div>
          <div className="order-item__user">
            <img src={userBaseImg} alt="" />
            <span>{order.user.userName}</span>
          </div>
          <div className="order-item__mail">{order.user.email}</div>
          <div className="order-item__total-price">{order.totalPrice} Pуб</div>
          <div className="order-item__status" style={styleColor}>
            <div style={styleBackground}></div>
            <span>{!order.processed ? 'Новый заказ' : 'Обработанный'}</span>
          </div>
          <div className="order-item__options">
            <i className="bi bi-gear-fill"></i>
            <i
              className={`bi bi-caret-down-fill ${hidden ? 'active' : ''}`}
              onClick={handleOrder}></i>
          </div>
        </div>
        <div className={hidden ? 'options' : 'options--hidden '}>
          <div className="options__params row">
            <p className="col-md-3">название</p>
            <p className="col-md-3">размер</p>
            <p className="col-md-3">цвет</p>
            <p className="col-md-3">количество</p>
          </div>

          {order.infoOrder.length ? (
            order.infoOrder.map((infoItem) => (
              <div className="row">
                <div className="options__content options__content--name col-md-3">
                  <p>{infoItem.name}</p>
                </div>
                <div className="options__content options__content--size col-md-3">
                  <p>{infoItem.selectParams.sizes}</p>
                </div>
                <div className="options__content options__content--color col-md-3">
                  <p>{infoItem.selectParams.color}</p>
                </div>
                <div className="options__content options__content--count col-md-3">
                  <p>{infoItem.count}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="row">
              <div className="options__content options__content--name col-md-3">
                <p>{order.infoOrder.name}</p>
              </div>
              <div className="options__content options__content--size col-md-3">
                <p>{order.infoOrder.selectParams.sizes}</p>
              </div>
              <div className="options__content options__content--color col-md-3">
                <p>{order.infoOrder.selectParams.color}</p>
              </div>
              <div className="options__content options__content--count col-md-3">
                <p>1</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderList;
