import React from 'react';
import { useNavigate } from 'react-router-dom';

function ProductEmpty() {
  const navigate = useNavigate();
  return (
    <div className="product-empty">
      <h2>
        Магазин пустует <span>😕</span>
      </h2>
      <p>Зайдите позже, мы стараемся изо всех сил</p>

      <p>
        <div onClick={() => navigate(-1)} className="button button--black">
          <i className="bi bi-chevron-compact-left"></i>
          Вернуться назад
        </div>
      </p>
    </div>
  );
}

export default ProductEmpty;
