import React from 'react';

import axios from '../utils/axios';
import MenuNavigation from '../components/MenuNavigation';
import ProductBlock from '../components/Products/ProductBlock';
import ProductEmpty from '../components/Products/ProductEmpty';
import { Link } from 'react-router-dom';

function Home() {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    axios
      .get(`/getAll`)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        console.warn(err);
        alert('Произошла ошибка');
      });
  }, []);

  if (!data) {
    return '';
  }

  return (
    <>
      <section class="welcome-banner">
        <div class="container">
          <div class="swiper welcomeBannerSwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <h1 class="col-sm-5 text-white">оп-оп покупай в вивт-шоп</h1>
              </div>
              <div class="swiper-slide">
                <h1 class="col-sm-5 text-white">оп-оп покупай в вивт-шоп</h1>
              </div>
              <div class="swiper-slide">
                <h1 class="col-sm-5 text-white">оп-оп покупай в вивт-шоп</h1>
              </div>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </section>
      <section class="about">
        <div class="container">
          <h1>О магазине</h1>
          <p class="col-9">
            В Воронежском институте высоких технологий открыт интернет-магазин digital-продуктов и
            товаров с корпоративной символикой, а также образовательных курсов или майноров.
            Обратите внимание, у студентов института и колледжа есть возможность оплатить покупку
            вивткоинами!
          </p>
        </div>
      </section>

      <section class="filter">
        <div class="container">
          <div class="row">
            <div class="col-sm-4">
              <div class="filter__item filter__item--mainors">
                <h1>Майноры/курсы</h1>
              </div>
            </div>
            <div class="col-sm-4">
              <Link to="/merch">
                <div class="filter__item filter__item--merch">
                  <h1>Мерч</h1>
                </div>
              </Link>
            </div>
            <div class="col-sm-4">
              <div class="filter__item filter__item--digital">
                <h1>Цифровые товары</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="stock">
        <div class="container">
          <div class="swiper stockSwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide stock__item">
                <h1>АКЦИЯ! день студента</h1>
                <a href="">Подробнее</a>
              </div>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </section>

      <section class="populate">
        <div class="container">
          <h1>Популярное</h1>
          <div class="swiper populateSwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide"></div>
              <div class="swiper-slide"></div>
              <div class="swiper-slide"></div>
              <div class="swiper-slide"></div>
              <div class="swiper-slide"></div>
              <div class="swiper-slide"></div>
              <div class="swiper-slide"></div>
              <div class="swiper-slide"></div>
              <div class="swiper-slide"></div>
            </div>
            <div class="swiper-scrollbar"></div>
          </div>
        </div>
      </section>
      <section class="have-time">
        <div class="container">
          <h1>успей купить</h1>
          <div class="row col-md-11 mx-auto">
            <div class="col-md-6">
              <div class="item">
                <div class="item__top">
                  <img src="../../images/have-time/popsoket.png" alt="" />
                </div>
                <div class="item__bottom">
                  <div class="item__title">
                    <h2>Попсокет “ВИВТ 3.0”</h2>
                    <h2 class="item__price">80 ₽</h2>
                  </div>
                  <p class="col-8">Многофункциональная подставка для мобильного устройства</p>
                  <div class="item__button">
                    <button>Купить</button>
                    <button>Купить за VIVTcoin</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="item">
                <div class="item__top">
                  <img src="../../images/have-time/popsoket.png" alt="" />
                </div>
                <div class="item__bottom">
                  <div class="item__title">
                    <h2>Попсокет “ВИВТ 3.0”</h2>
                    <h2 class="item__price">80 ₽</h2>
                  </div>
                  <p class="col-8">Многофункциональная подставка для мобильного устройства</p>
                  <div class="item__button">
                    <button>Купить</button>
                    <button>Купить за VIVTcoin</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="collections">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="collection">
                <h1>Колллекция “VIVTFamily”</h1>
              </div>
            </div>
            <div class="col-md-4">
              <div class="collection">
                <h1>Колллекция “VIVTFamily”</h1>
              </div>
            </div>
            <div class="col-md-4">
              <div class="collection">
                <h1>Колллекция “VIVTFamily”</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
    // <section className="home">
    //   <div className="home__container row">
    //     <div className="col-lg-3">
    //       <MenuNavigation />
    //     </div>
    //     <div className="col-lg-9 row">
    //       {data.products ? (
    //         data.products.map((item) => (
    //           <ProductBlock
    //             name={item.name}
    //             description={item.description}
    //             price={item.price}
    //             imageUrl={item.imgUrl}
    //             id={item._id}
    //             key={item._id}
    //           />
    //         ))
    //       ) : (
    //         <ProductEmpty />
    //       )}
    //     </div>
    //   </div>
    // </section>
  );
}

export default Home;
