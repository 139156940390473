import React from 'react';
import { useParams } from 'react-router-dom';

import axios from '../../utils/axios';
import DropZone from '../../components/AdminPanel/DropZone';
import ProductInputFields from '../../components/AdminPanel/ProductInputFields';

function GetProduct() {
  const [stateImagesIndex, setStateImagesIndex] = React.useState({ images: [], mainImage: 0 });

  const { id } = useParams();
  const [data, setData] = React.useState();

  React.useEffect(() => {
    Boolean(id) &&
      axios
        .get(`/get/${id}`)
        .then(({ data }) => {
          setData(data);
        })
        .catch((err) => {
          console.warn(err);
          alert('Произошла ошибка');
        });
  }, [id]);
  if (Boolean(id) && !data) {
    return '';
  }

  return (
    <div className="row">
      <div className="col-8 product-input-fields">
        <ProductInputFields stateImagesIndex={stateImagesIndex} productData={data ? data : false} />
      </div>
      <div className="col-4">
        <DropZone
          stateImagesIndex={stateImagesIndex}
          setStateImagesIndex={setStateImagesIndex}
          ArrayImages={data ? data.specification.images : false}
        />
      </div>
    </div>
  );
}

export default GetProduct;
