import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { fetchUserReg } from '../../redux/slice/userSlice';

function RegisterPanel({ status }) {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      userName: '',
      password: '',
    },
    mode: 'all',
  });

  const onSubmit = (params) => {
    dispatch(fetchUserReg(params));
  };

  const style = {
    display: status ? 'none' : 'block',
  };

  return (
    <div className="form-container sign-up-container" style={style}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1>Регистрация</h1>
        <div className="social-container">
          <Link to="#" className="social">
            Регистрация через сайт VIVT<i className="fa-solid fa-building-columns"></i>
          </Link>
        </div>
        <span>или используйте свою электронную почту для регистрации</span>
        <input
          type="text"
          placeholder="Name"
          {...register('userName', {
            required: 'Укажите ФИО',
            minLength: {
              value: 10,
              message: '10 минимальное количество символов',
            },
          })}
        />
        {errors.name && <span className="form-container__errors">{errors.name.message}</span>}

        <input
          type="email"
          placeholder="Email"
          {...register('email', {
            required: 'Укажите почту',
            pattern: {
              value: /^\S+@\S+$/i,
              message: `почтовый адрес ${getValues('email')} не действительный`,
            },
            minLength: {
              value: 10,
              message: '10 минимальное количество символов',
            },
          })}
        />
        {errors.email && <span className="form-container__errors">{errors.email.message}</span>}
        <input
          type="password"
          autoComplete="off"
          placeholder="Password"
          {...register('password', {
            required: 'Укажите пароль',
            minLength: {
              value: 6,
              message: '6 минимальное количество символов',
            },
          })}
        />
        <span className="form-container__errors">{errors.password?.message}</span>

        <button type="submit">Зарегистрироваться</button>
      </form>
    </div>
  );
}

export default RegisterPanel;
