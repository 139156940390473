import React from 'react';
import { useSelector } from 'react-redux';

import CartBlock from '../components/Cart/CartBlock';
import CartEmpty from '../components/Cart/CartEmpty';
import { selectCartTotalPrice } from '../redux/slice/cartSlice';

function Cart() {
  const totalPrice = useSelector(selectCartTotalPrice);

  return (
    <section>
      <div className="container">{!totalPrice ? <CartEmpty /> : <CartBlock />}</div>
    </section>
  );
}

export default Cart;
