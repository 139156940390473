import React from 'react';

import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { reIsLoading } from '../../redux/slice/productSlice';

import axios from '../../utils/axios';
import ToolTip from '../ToolTip';

function DropZone({ stateImagesIndex, setStateImagesIndex, ArrayImages }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.product);

  const [myFiles, setMyFiles] = React.useState([]);

  React.useEffect(() => {
    let newArray = [];
    myFiles.forEach((file) => {
      newArray.push(file.path);
    });

    setStateImagesIndex({ ...stateImagesIndex, images: newArray.slice() });
  }, [myFiles]);

  React.useEffect(() => {
    ArrayImages && setStateImagesIndex({ ...stateImagesIndex, images: ArrayImages.slice() });
  }, []);

  const removeUpdateFile = (file) => () => {
    const newFiles = [...stateImagesIndex.images];
    newFiles.splice(newFiles.indexOf(file), 1);
    setStateImagesIndex({ ...stateImagesIndex, images: newFiles.slice() });
  };

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
    setStateImagesIndex({ ...stateImagesIndex, mainImage: null });
  };

  const handleChange = async () => {
    try {
      const formData = new FormData();
      myFiles.map((file) => {
        return formData.append('image', file);
      });
      await axios.post('/upload', formData).then(dispatch(reIsLoading())).then(removeAll());
    } catch (err) {
      console.log(err);
      alert('Ошибка при загрузке файла!');
    }
  };

  if (isLoading) {
    handleChange();
  }

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((obj) => {
        let a = Boolean(myFiles.find((item) => item.path === obj.path));
        if (myFiles.length < 6 && !a && acceptedFiles.length < 6) {
          console.log(obj);
          return setMyFiles((oldArray) => [
            ...oldArray,
            Object.assign(obj, {
              preview: URL.createObjectURL(obj),
            }),
          ]);
        } else if (a) {
          return alert('Вы уже добавили данную картинку. Пожалуйста, будьте внимательны');
        } else {
          return alert('Лимит файлов превышен');
        }
      });
    },
    [myFiles],
  );

  //
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 6,
    accept: {
      'image/*': [],
    },
    onDrop,
  });

  const files = myFiles.map((file, index) => (
    <div key={file.path}>
      <div style={{ backgroundImage: `url(${file.preview})` }} className="load-files__image">
        <i onClick={removeFile(file)} className="bi bi-x-circle"></i>
      </div>
      <div htmlFor="">
        <ToolTip text="Данный курсор выбирает главную картинку" />
        <input
          type="radio"
          name="mainImage"
          value={index}
          onChange={(e) => setStateImagesIndex({ ...stateImagesIndex, mainImage: e.target.value })}
        />
      </div>
    </div>
  ));

  React.useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div className="load-files ">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <span>Зона загрузки файлов </span>
        <ToolTip
          text={
            'Чтобы загрузить файл, нажмите на зону загрузки или перетащите файл в данную область'
          }
        />
      </div>
      <aside>
        {ArrayImages.length > 0 &&
          stateImagesIndex.images.map((file, index) => (
            <div key={file}>
              <div>
                <img
                  className="load-files__image"
                  src={`http://localhost:5000/api/uploads/${file}`}
                  alt=""
                />
                <i onClick={removeUpdateFile(file)} className="bi bi-x-circle"></i>
              </div>
              <div htmlFor="">
                <ToolTip text="Данный курсор выбирает главную картинку" />
                <input
                  type="radio"
                  name="mainImage"
                  value={index}
                  onChange={(e) =>
                    setStateImagesIndex({ ...stateImagesIndex, mainImage: e.target.value })
                  }
                />
              </div>
            </div>
          ))}
      </aside>
      <aside>{files}</aside>
      {files.length > 0 && (
        <button onClick={removeAll}>
          Очистить <i className="bi bi-trash"></i>
        </button>
      )}
    </div>
  );
}

export default DropZone;
