import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OrderList from '../../components/AdminPanel/OrderList';
import { fetchGetAllOrder } from '../../redux/slice/orderSlice';

function GetOrder() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.order);

  React.useEffect(() => {
    dispatch(fetchGetAllOrder());
  }, [dispatch]);
  if (!data) {
    return '';
  }
  return (
    <div>
      {/* {data.map((item) => (
        <div key={item._id}>
          <div>
            <p>Id Заказа: {item._id}</p>
            <p>Имя пользователя: {item.user.userName}</p>
          </div>
          <br />
          {item.infoOrder.length ? (
            item.infoOrder.map((infoItem) => (
              <div>
                <p>Название товара: {infoItem.name}</p>
                <p>Размер товара: {infoItem.selectParams.sizes}</p>
                <p>Цвет товара: {infoItem.selectParams.color}</p>
                <p>Количество товара: {infoItem.count}</p>
                <p>Цена товара: {infoItem.price}</p>
                <br />
              </div>
            ))
          ) : (
            <div>
              <p>Название товара: {item.name}</p>
              <p>Размер товара: {item.infoOrder.selectParams.sizes}</p>
              <p>Цвет товара: {item.infoOrder.selectParams.color}</p>
              <p>Количество товара: {item.count}</p>
              <p>Цена товара: {item.infoOrder.price}</p>
              <br />
            </div>
          )}
          <div>
            <p>Цена Заказа: {item.totalPrice}</p>
            <p>Дата создания заказа: {item.createdAt}</p>
            <p>Дата обновления заказа: {item.updatedAt}</p>
            <p>Статус Заказа: {!item.processed ? 'Новый заказ' : 'Обработанный'}</p>
          </div>

          <br />
          <br />
          <hr />
        </div>
      ))} */}
      {data.map((order) => (
        <OrderList key={order._id} order={order} />
      ))}
    </div>
  );
}

export default GetOrder;
