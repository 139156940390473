import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectIsAuth } from '../../redux/slice/userSlice';
import MenuHeader from './Menu';
import { calcCountLength } from '../../utils/calcCountLength';
import { selectCart } from '../../redux/slice/cartSlice';

function Header() {
  const { items, totalPrice } = useSelector(selectCart);
  const isAuth = useSelector(selectIsAuth);
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <header className="header">
      <div className="header__container ">
        <div className="header__top">
          <div className="header__logo ">
            <div className="header__burger icon-menu show-more">
              <i className="bi bi-list" onClick={() => setIsOpen((prev) => !prev)}></i>
            </div>
            <Link to="/" className="header__logo logo">
              ВИВТ Ш-ОП
            </Link>
          </div>
          <div className="header__filters col-8">
            <nav className="col-7">
              <ul className="">
                <li className="">
                  <Link to="/login" className="menu__link">
                    Курсы
                  </Link>
                </li>
                <li className="">
                  <Link to="/merch" className="menu__link">
                    Мерч
                  </Link>
                </li>
                <li className="">
                  <Link to="#" className="menu__link">
                    Цифровые товары
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="header__search col-5">
              <input type="text" placeholder="Поиск..." />
              <i className="bi bi-search"></i>
            </div>
          </div>

          {isAuth ? (
            <div className="header__icons col-2">
              <Link to={'/lk'} className="header__icon">
                <i className="bi bi-person-fill"></i>
              </Link>
              <Link to={'/cart'} className="header__icon">
                <i className="bi bi-basket "></i>
                {totalPrice ? (
                  <span className="header__cart-count">{calcCountLength(items)}</span>
                ) : (
                  ''
                )}
              </Link>
            </div>
          ) : (
            <div className="header__icons  col-2">
              <Link className="header__button-link" to="/login">
                Войти
              </Link>
              <Link className="header__button-link" to="/login">
                Регистрация
              </Link>
            </div>
          )}
        </div>
      </div>
      <MenuHeader status={isOpen} />
    </header>
  );
}

export default Header;
