import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { selectCart } from './redux/slice/cartSlice';
import { fetchAuthMe } from './redux/slice/userSlice';
import Header from './components/header/Index';
import Authentication from './pages/Authentication';
import Cart from './pages/Cart';

import AuthorizationVivt from './components/Authentication/AuthorizationVivt';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import FullProduct from './pages/FullProduct';
import Lk from './pages/Lk';
import AdminPanel from './pages/AdminPanel/index';
import GetProduct from './pages/AdminPanel/GetProduct';

import './scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';
import ManagementFilter from './pages/AdminPanel/ManagementFilter';
import MyFavorites from './pages/MyFavorites';
import GetOrder from './pages/AdminPanel/GetOrder';
import Merch from './pages/Merch';
import Products from './pages/Products';
import Collections from './pages/AdminPanel/Collections';
import CollectionsCreate from './pages/AdminPanel/CollectionsCreate';
import Collection from './pages/Collection';

function App() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchAuthMe());
  }, [dispatch]);

  const { items } = useSelector(selectCart);
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    if (isMounted.current) {
      const json = JSON.stringify(items);
      localStorage.setItem('cart', json);
    }
    isMounted.current = true;
  }, [items]);

  return (
    <>
      <div className="main__container">
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/login" element={<Authentication />} />
          <Route path="/auth/login/:key?" element={<AuthorizationVivt />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/products" element={<Products />} />
          <Route path="/collection/:id" element={<Collection />} />
          <Route path="/merch" element={<Merch />} />
          <Route path="/product/:id" element={<FullProduct />} />
          <Route path="/lk" element={<Lk />} />
          <Route path="/lk/favorites" element={<MyFavorites />} />
          <Route path="/admin-panel" element={<AdminPanel />}>
            <Route path="" element={'Главная'} />
            <Route path="get-product" element={<GetProduct />} />
            <Route path="product/:id/edit" element={<GetProduct />} />
            <Route path="management-filter" element={<ManagementFilter />} />
            <Route path="management-collections" element={<Collections />} />
            <Route path="management-read-collections" element={<Collections />} />
            <Route path="management-create-collections" element={<CollectionsCreate />} />
            <Route path="get-all-order" element={<GetOrder />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        type="info"
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </>
  );
}

export default App;
