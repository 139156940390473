import React from 'react';

const NotFound = () => {
  return (
    <section>
      <div className={'notFound container'}>
        <h1>
          <span>😔</span>
          <br />
          Ничего не найдено
        </h1>
        <p>
          Возможно, запрашиваемая Вами страница была перенесена или удалена. Также возможно, Вы
          допустили небольшую опечатку при вводе адреса – такое случается, попробуйте начать с
          главной страницы.
        </p>
      </div>
    </section>
  );
};
export default NotFound;
