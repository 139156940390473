import React from 'react';

function ProductDnd({ item }) {
  const onDragStart = (ev, name) => {
    ev.dataTransfer.setData('id', name);
  };

  return (
    <div draggable className="draggable" onDragStart={(e) => onDragStart(e, item.name)}>
      <img src={`https://shop.vivt.ru/api/uploads/${item.image}`} alt="" />
      <p> {item.name}</p>
    </div>
  );
}

export default ProductDnd;
