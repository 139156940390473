import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../redux/slice/userSlice';

function MenuNavigation() {
  const dispatch = useDispatch();
  return (
    <div className="menu-navigation ">
      <div>
        <h1>Меню</h1>
        <ul className="">
          <li>
            <Link to="/lk">Аккаунт</Link>
          </li>
          <li>
            <Link to="/admin-panel">Админ панель</Link>
          </li>
          <li>
            <Link to="/lk/favorites">Мои желания</Link>
          </li>
          <li>Мои майноры</li>
          <li>
            <Link to="/cart">Корзина</Link>
          </li>
          <li>Помощь</li>
          <li onClick={() => dispatch(logout())}>Выйти</li>
        </ul>
      </div>
    </div>
  );
}

export default MenuNavigation;
