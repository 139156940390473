import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

export const fetchCreateOrder = createAsyncThunk('order/create', async ({ ...params }) => {
  try {
    const { data } = await axios.post('/order/create', params);
    return data;
  } catch (e) {
    console.log(e);
  }
});

export const fetchGetAllOrder = createAsyncThunk('order/getAll', async () => {
  try {
    const { data } = await axios.get('/order/getAll');

    return data;
  } catch (e) {
    console.log(e);
  }
});

const initialState = {
  data: null,
  newOrder: 0,
};
const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetAllOrder.fulfilled, (state, action) => {
      state.data = action.payload;

      state.newOrder = state.data.reduce((sum, obj) => {
        !obj.processed && sum++;
        return sum;
      }, 0);
    });
  },
});

export const orderReducer = orderSlice.reducer;
