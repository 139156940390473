import React from 'react';

import axios from '../utils/axios';
import MenuNavigation from '../components/MenuNavigation';
import ProductBlock from '../components/Products/ProductBlock';
import ProductEmpty from '../components/Products/ProductEmpty';

function Products() {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    axios
      .get(`/getAll`)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        console.warn(err);
        alert('Произошла ошибка');
      });
  }, []);

  if (!data) {
    return '';
  }

  return (
    <section className="home">
      <div className="home__container row">
        <div className="col-lg-3">
          <MenuNavigation />
        </div>
        <div className="col-lg-9 ">
          <ul class="cards">
            {data.products ? (
              data.products.map((item) => (
                <li>
                  <ProductBlock
                    name={item.name}
                    description={item.description}
                    price={item.price}
                    imageUrl={item.imgUrl}
                    id={item._id}
                    key={item._id}
                  />
                </li>
              ))
            ) : (
              <ProductEmpty />
            )}
          </ul>

          {/* {data.products ? (
            data.products.map((item) => (
              <ProductBlock
                name={item.name}
                description={item.description}
                price={item.price}
                imageUrl={item.imgUrl}
                id={item._id}
                key={item._id}
              />
            ))
          ) : (
            <ProductEmpty />
          )} */}
        </div>
      </div>
    </section>
  );
}

export default Products;
