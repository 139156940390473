import React from 'react';
import { useNavigate } from 'react-router-dom';

import cartEmptyImg from '../../assets/img/empty-cart.db905d1f4b063162f25b.png';

function CartEmpty() {
  const navigate = useNavigate();
  return (
    <div className="cart cart--empty">
      <h2>
        Корзина пустая <span>😕</span>
      </h2>
      <p>
        Вероятней всего, вы не заказывали наш товар.
        <br />
        Для того, чтобы сделать заказ, перейди на главную страницу.
      </p>
      <img src={cartEmptyImg} alt="Empty cart" />
      <div onClick={() => navigate(-1)} className="button button--black">
        <i className="bi bi-chevron-compact-left"></i>
        Вернуться назад
      </div>
    </div>
  );
}

export default CartEmpty;
