import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useForm } from 'react-hook-form';

import { fetchUserLogin } from '../../redux/slice/userSlice';
import { clearMessage } from '../../redux/slice/messageSlice';

function LoginPanel({ status }) {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'all',
  });

  const onSubmit = (params) => {
    dispatch(fetchUserLogin(params));
  };

  const loginVivt = () => {
    window.location.assign('https://vivt.ru/cron/check-auth-user');
  };

  React.useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const style = {
    display: status ? 'block' : 'none',
  };

  return (
    <div className="form-container sign-in-container" style={style}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1>Войти</h1>
        <div className="social-container">
          <Link className="social" onClick={() => loginVivt()}>
            Войти через сайт VIVT<i className="fa-solid fa-building-columns"></i>
          </Link>
        </div>
        <span>или используйте свою учетную запись</span>
        <input
          type="email"
          placeholder="Email"
          {...register('email', {
            required: 'Укажите почту',
            pattern: {
              value: /^\S+@\S+$/i,
              message: `почтовый адрес ${getValues('email')} не действительный`,
            },
            minLength: {
              value: 10,
              message: '10 минимальное количество символов',
            },
          })}
        />
        {errors.email && <span className="form-container__errors">{errors.email.message}</span>}

        <input
          type="password"
          autoComplete="off"
          placeholder="Password"
          {...register('password', {
            required: 'Укажите пароль',
            minLength: {
              value: 6,
              message: '6 минимальное количество символов',
            },
          })}
        />
        {Boolean(errors.password?.message) && (
          <span className="form-container__errors">{errors.password?.message}</span>
        )}
        <Link to="">Забыли свой пароль?</Link>
        <button type="submit">Войти</button>
      </form>
    </div>
  );
}

export default LoginPanel;
