import React from 'react';

const HoverableDiv = ({ handleMouseOver, handleMouseOut }) => {
  return (
    <div className="tool-tip__faq" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <i className="bi bi-question-circle-fill"></i>
    </div>
  );
};

const HoverText = ({ text }) => {
  return (
    <div className="tool-tip__faq-hidden">
      <i className="bi bi-question-circle-fill"></i>
      <span>{text}</span>
    </div>
  );
};

function ToolTip({ text }) {
  const [isHovering, setIsHovering] = React.useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div className="tool-tip">
      <HoverableDiv handleMouseOver={handleMouseOver} handleMouseOut={handleMouseOut} />
      {isHovering && <HoverText text={text} />}
    </div>
  );
}

export default ToolTip;
