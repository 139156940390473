import React from 'react';

import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import imageProducts from '../../assets/img/Rectangle 38.png';

function MyComments() {
  return (
    <section className="my-comments">
      <h1>Мои Комментарии</h1>

      <Swiper pagination={true} modules={[Autoplay]} slidesPerView={2} className="mySwiper">
        <SwiperSlide>
          <div className="row">
            <div className="col-3">
              <div className="my-comments__image">
                <img src={imageProducts} alt="" />
              </div>
            </div>
            <div className="col-9">
              <div className="my-comments__desc ">
                «Хочу сказать огромное спасибо команде МИФа, спикерам и всем кто принимал участие в
                помощи принять и полюбить себя! Вы научили нас работать с различными практиками,
                которые помогают увидеть саму суть, докопаться до истины и узнать где здесь мое, а
                где чужое (это про чужие установки)! Этот курс !»
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
}

export default MyComments;
