import React from 'react';
import { Link } from 'react-router-dom';

import axios from '../../utils/axios';

function Collections() {
  const [data, setData] = React.useState();

  const getCollections = () => {
    axios.get('/collection/getAll').then(({ data }) => {
      setData(data);
    });
  };
  React.useEffect(() => {
    getCollections();
  }, []);

  const removeCollection = (id, index) => {
    axios.delete(`/collection/remove/${id}`);
    getCollections();
  };

  if (!data) {
    return '';
  }
  return (
    <div>
      <Link to="/admin-panel/management-create-collections">Создать</Link>

      <div className="row">
        {data.map((element, index) => (
          <div key={element._id} className="col-md-3">
            <h1>{element.name}</h1>

            <img src={`http://localhost:5000/api/uploads/${element.preview}`} alt="" width="100%" />
            <p onClick={() => removeCollection(element._id, index)}>удалить</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Collections;
