import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './slice/userSlice';
import { productReducer } from './slice/productSlice';
import { cartReducer } from './slice/cartSlice';
import messageReducer from './slice/messageSlice';
import { sortReducer } from './slice/sortSlice';
import { orderReducer } from './slice/orderSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    message: messageReducer,
    product: productReducer,
    cart: cartReducer,
    sort: sortReducer,
    order: orderReducer,
  },
  devTools: true,
});

export default store;
