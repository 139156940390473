import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { clearItem, selectCart } from '../../redux/slice/cartSlice';
import { fetchCreateOrder } from '../../redux/slice/orderSlice';
import { calcCountLength } from '../../utils/calcCountLength';
import CartItems from './CartItems';

function CartBlock() {
  const dispatch = useDispatch();
  const { items, totalPrice } = useSelector(selectCart);
  console.log(items);
  const navigate = useNavigate();

  const fetchOrder = () => {
    dispatch(fetchCreateOrder({ items, totalPrice })).then(({ payload }) =>
      payload.status ? toast.success(payload.message) : toast.error(payload.message),
    );
    navigate('/');
    dispatch(clearItem());
  };

  return (
    <>
      <div className="cart">
        <div className="cart__top">
          <h2 className="content__title">
            <i className="bi bi-cart2"></i>
            Корзина
          </h2>
          <div className="cart__clear">
            <i className="bi bi-trash"></i>
            <span
              onClick={() => {
                dispatch(clearItem());
              }}>
              Очистить корзину
            </span>
          </div>
        </div>
        <div className="content__items">
          {items.map((item) => (
            <CartItems key={item.id} {...item} />
          ))}
        </div>
        <div className="cart__bottom">
          <div className="cart__bottom-details">
            <span>
              Всего товара: <b>{calcCountLength(items)} шт.</b>{' '}
            </span>
            <span> Сумма заказа: {totalPrice && <b>{totalPrice} ₽</b>} </span>
          </div>
          <div className="cart__bottom-buttons">
            <div
              onClick={() => navigate(-1)}
              className="button button--outline button--add go-back-btn">
              <i className="bi bi-chevron-compact-left"></i>
              <span>Вернуться назад</span>
            </div>
            <div className="button pay-btn" onClick={() => fetchOrder()}>
              <span>Оплатить сейчас</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CartBlock;
