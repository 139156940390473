import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import ToolTip from '../ToolTip';

import axios from '../../utils/axios';
import { fetchAddProduct, updateIsLoading } from '../../redux/slice/productSlice';
import {
  fetchSortCategory,
  fetchSortSubcategory,
  fetchSortType,
} from '../../redux/slice/sortSlice';

function ProductInputFields({ stateImagesIndex, productData }) {
  const dispatch = useDispatch();
  const { type, category, subcategory } = useSelector((state) => state.sort);
  let activeButton = Boolean(stateImagesIndex.images.length > 0);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: productData
      ? {
          name: productData.name,
          price: productData.price,
          description: productData.description,

          sizes: productData.specification.sizes,
          selectSize: productData.specification.selectSize,
          colors: productData.specification.colors,
          materials: productData.specification.materials,
          quantity: productData.quantity,
        }
      : '',
    mode: 'all',
  });

  React.useEffect(() => {
    dispatch(fetchSortType());
    dispatch(fetchSortCategory());
    dispatch(fetchSortSubcategory());
  }, [dispatch]);

  const createProduct = (infoProduct) => {
    dispatch(updateIsLoading());
    dispatch(fetchAddProduct({ infoProduct, stateImagesIndex })).then((data) => {
      toast(data.payload.message);
      reset();
    });
  };
  const updateProduct = async (infoProduct) => {
    await axios
      .patch(`/updateProduct/${productData._id}`, { infoProduct, stateImagesIndex })
      .then(({ data }) => {
        console.log(data);
        toast(data.message);
      });
  };

  if (!type || !category || !subcategory) {
    return 'Загрузка....';
  }

  return (
    <form onSubmit={productData ? handleSubmit(updateProduct) : handleSubmit(createProduct)}>
      <div className="product-input-fields__selected ">
        <label className="col-3">
          Тип товара
          <select {...register('typeId', { required: 'Укажите тип товара ' })} selected="selected">
            {productData && (
              <option value={productData.typeId._id}>{productData.typeId.title}</option>
            )}
            {type.map((type) => (
              <option key={type._id} value={type._id}>
                {type.title}
              </option>
            ))}
          </select>
          {errors.typeId && <span className="errors">{errors.typeId.message}</span>}
        </label>

        <label className="col-3">
          Категория товара
          <select {...register('categoryId', { required: 'Укажите категорию товара ' })}>
            {productData && (
              <option value={productData.categoryId._id}>{productData.categoryId.title}</option>
            )}
            {category.map((category) => (
              <option key={category._id} value={category._id}>
                {category.title}
              </option>
            ))}
          </select>
          {errors.categoryId && <span className="errors">{errors.categoryId.message}</span>}
        </label>

        <label className="col-3">
          Подкатегория товара
          <select {...register('subcategoryId', { required: 'Укажите подкатегорию товара ' })}>
            {productData && (
              <option value={productData.subcategoryId._id}>
                {productData.subcategoryId.title}
              </option>
            )}
            {subcategory.map((subcategory) => (
              <option key={subcategory._id} value={subcategory._id}>
                {subcategory.title}
              </option>
            ))}
          </select>
          {errors.subcategoryId && <span className="errors">{errors.subcategoryId.message}</span>}
        </label>
      </div>
      <label className="col-12">
        Имя товара
        <input
          type="text"
          placeholder="'Футболка ВИВТ'"
          {...register('name', { required: 'Укажите название товара' })}
        />
        {errors.name && <span className="errors">{errors.name.message}</span>}
      </label>
      <label className="col-12 ">
        <span>
          Цена товара
          <ToolTip text="Цена товара указывается в цифрах без сторонних символов" />
        </span>

        <input
          type="number"
          {...register('price', {
            required: 'Укажите цену товара',
            min: {
              value: 0,
              message: 'Значение не может быть отрицательным',
            },
          })}
        />

        {errors.price && <span className="errors">{errors.price.message}</span>}
      </label>
      <label>
        <span>Описание товара</span>

        <textarea
          {...register('description', {
            required: 'Укажите Описание товара',
            minLength: {
              value: 20,
              message: '20 минимальное количество символов',
            },
          })}
        />

        {errors.description && <span className="errors">{errors.description.message}</span>}
      </label>
      <label>
        <span>Уход за товаром товара</span>

        <textarea {...register('care')} />
      </label>
      <div className="product-input-fields__inputs">
        <label className="col-3">
          <span>
            Размер товара
            <ToolTip text="Размеры нужно указывать через запятую без пробела. Пример: l,xl" />
          </span>
          <input
            type="text"
            placeholder="Size"
            {...register('sizes', { required: 'Укажите размеры ' })}
          />
          <div className="product-input-fields__radio">
            выбор Элемента <input type="checkbox" {...register('selectSize')} />
          </div>
          {(errors.sizes && <span className="errors">{errors.sizes.message}</span>) ||
            (errors.selectSize && <span className="errors">{errors.selectSize.message}</span>)}
        </label>

        <label className="col-3">
          <span>
            Цвета товара
            <ToolTip text="Цвета нужно указывать через запятую без пробела. Пример: черный,белый" />
          </span>

          <input
            type="text"
            placeholder="Color"
            {...register('colors', { required: 'Укажите цвета ' })}
          />

          {errors.colors && <span className="errors">{errors.colors.message}</span>}
        </label>

        <label className="col-3">
          <span>Материалы товара</span>
          <input
            type="text"
            placeholder="materials"
            {...register('materials', { required: 'Укажите Материалы ' })}
          />
          {errors.materials && <span className="errors">{errors.materials.message}</span>}
        </label>
      </div>
      <label className="col-3">
        <span>Количество товара</span>
        <input type="number" {...register('quantity')} />
        {errors.quantity && <span className="errors">{errors.quantity.message}</span>}
      </label>
      {activeButton && (
        <button type="submit">{productData ? 'Изменить товар' : 'Добавить товар'}</button>
      )}
    </form>
  );
}

export default ProductInputFields;
