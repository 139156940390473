import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from '../../utils/axios';
import { addItem } from '../../redux/slice/cartSlice';
import { setMessage } from '../../redux/slice/messageSlice';
import { toast } from 'react-toastify';

import { fetchCreateOrder } from '../../redux/slice/orderSlice';
import { Link, useNavigate } from 'react-router-dom';

function ProductInfo({
  id,
  name,
  price,
  description,
  sizes,
  selectSize,
  colors,
  materials,
  imageUrl,
}) {
  const [favorite, setFavorite] = React.useState();
  const [selectParams, setSelectParams] = React.useState({
    sizes: 'default',
    color: 'default',
  });

  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  const { data } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const onClickAdd = () => {
    const item = {
      id,
      name,
      price,
      imageUrl,
      selectParams,
    };
    dispatch(addItem(item));
  };

  const fetchOrder = () => {
    dispatch(fetchCreateOrder({ items: { name, price, selectParams }, totalPrice: price })).then(
      ({ payload }) =>
        payload.status ? toast.success(payload.message) : toast.error(payload.message),
    );
  };

  React.useEffect(() => {
    axios.get(`/favourites/isProductFavourite/${id}`).then(({ data }) => {
      data ? setFavorite(true) : setFavorite(false);
    });
  }, [message, id]);

  const fetchAddFavourite = async () => {
    await axios.post('/favourites/addRemove', { productId: id }).then(({ data }) => {
      dispatch(setMessage(data.message));
    });
  };

  const deleteProduct = async () => {
    if (window.confirm('Вы действительно хотите выйти?')) {
      await axios.delete(`/removeProduct/${id}`);
      navigate('/');
    }
  };

  const style = {
    color: favorite ? '#ff5a5af4' : 'black',
  };

  return (
    <div className="itemMain__info">
      <div>
        <h1>{name}</h1>
        <h2>{price} ₽</h2>
        <div className="itemMain__description">
          <p>{description}</p>

          <div className="itemMain__materials">
            <p>
              <b>Размер:</b>
            </p>
            <div className="tabs">
              {selectSize ? (
                sizes.map((item, index) => (
                  <div key={index}>
                    <input
                      type="radio"
                      id={`${item}`}
                      name="tabsSize"
                      onChange={(e) => setSelectParams({ ...selectParams, sizes: e.target.value })}
                    />
                    <label className="tab" htmlFor={`${item}`}>
                      <p>{item}</p>
                    </label>
                  </div>
                ))
              ) : (
                <p>{sizes}</p>
              )}
            </div>
          </div>
          <div className="itemMain__materials">
            <p>
              <b>Цвет:</b>
            </p>
            <div className="tabs tabs--color">
              {colors.map((item, index) => (
                <div key={index}>
                  <input type="radio" id={`radioColor-${index}`} name="tabsColor" />
                  <label className="tab" htmlFor={`radioColor-${index}`}>
                    <p>{item}</p>
                  </label>
                </div>
              ))}
            </div>
          </div>

          <p>
            <b>Материал:</b> {materials + ','}
          </p>

          <p>
            <b>Рекомендуемый уход:</b> перед стиркой выверните толстовку на изнанку, стирать
            рекомендуется на деликатном режиме при 40 градусах.
          </p>
        </div>
        <div className="itemMain__button">
          <div>
            <button onClick={() => fetchOrder()}>Купить</button>
            <button onClick={onClickAdd}>В корзину</button>
            {data && data.user.roles.includes('ADMIN') ? (
              <div className="itemMain__button-admin">
                <button onClick={deleteProduct}>удалить</button>
                <Link to={`/admin-panel/product/${id}/edit`}>
                  <button>Редактировать</button>
                </Link>
              </div>
            ) : (
              ''
            )}
          </div>

          <span onClick={fetchAddFavourite} style={style}>
            «Мне понравился товар»
          </span>
        </div>
      </div>
    </div>
  );
}

export default ProductInfo;
