import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import axios from '../../utils/axios';
import ProductDnd from '../../components/AdminPanel/ProductDnd';

const CollectionsCreate = () => {
  const [tasks, setTasks] = useState([]);
  const [nameCollection, setName] = useState('');

  React.useEffect(() => {
    axios
      .get(`/getAll`)
      .then(({ data }) => {
        const newTasks = data.products.map((item) => ({
          id: item._id,
          name: item.name,
          image: item.imgUrl[0],
          location: 'start',
        }));
        setTasks((prevTasks) => [...prevTasks, ...newTasks]);
      })
      .catch((err) => {
        console.log(err);
        alert('Произошла ошибка');
      });
  }, []);

  const onDragOver = (ev) => {
    ev.preventDefault();
  };

  const onDrop = (ev, location) => {
    let id = ev.dataTransfer.getData('id');

    let newTasks = tasks.map((item) => {
      if (item.name === id) {
        return { ...item, location: location };
      }
      return item;
    });

    setTasks(newTasks);
  };

  const taskObj = {
    start: [],
    complete: [],
  };

  tasks.forEach((item) => {
    taskObj[item.location].push(<ProductDnd key={item.id} item={item} />);
  });

  const createCollection = async () => {
    const formData = new FormData();
    formData.append('name', nameCollection);
    formData.append('image', acceptedFiles[0]);
    formData.append('preview', acceptedFiles[0].path);
    taskObj.complete.forEach((item) => formData.append('products', item.key));
    await axios.post('/collection/create', formData);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="container-drag">
      <div>
        <h1>Название коллекции</h1>
        <input type="text" value={nameCollection} onChange={(e) => setName(e.target.value)} />
      </div>
      <div
        className="start"
        onDragOver={(e) => onDragOver(e)}
        onDrop={(e) => {
          onDrop(e, 'start');
        }}>
        <span className="task-header">Продукты</span>
        {taskObj.start}
      </div>
      <div
        className="droppable"
        onDragOver={(e) => onDragOver(e)}
        onDrop={(e) => onDrop(e, 'complete')}>
        <span className="task-header">Коллекция</span>
        {taskObj.complete}
      </div>
      <div>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <aside>
          <h4>Files</h4>
          <ul>{files}</ul>
        </aside>
      </div>
      <button onClick={createCollection}>Отправить</button>
    </div>
  );
};

export default CollectionsCreate;
