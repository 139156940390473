import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectIsAuth } from '../../redux/slice/userSlice';
import Menu from '../../components/AdminPanel/Menu';

function AdminPanel() {
  const { data } = useSelector((state) => state.auth);
  const isAuth = useSelector(selectIsAuth);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (data) {
      data.user.roles.map((role) => (role !== 'ADMIN' ? navigate('/lk') : navigate()));
    }
    if (!isAuth && !window.localStorage.getItem('token')) {
      navigate('/login');
    }
  }, [isAuth, navigate, data]);

  return (
    <section className="admin-panel row">
      <div className="col-2">
        <Menu />
      </div>
      <div className="col-10">
        <div className="admin-panel__body ">
          <Outlet />
        </div>
      </div>
    </section>
  );
}

export default AdminPanel;
