import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { selectIsAuth } from '../../redux/slice/userSlice';
import { fetchUserLoginVivt } from '../../redux/slice/userSlice';

import axios from 'axios';

function AuthorizationVivt() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = useSelector(selectIsAuth);
  const { message } = useSelector((state) => state.message);

  const [searchParams, setSearchParams] = useSearchParams();
  const key = searchParams.get('key');

  React.useEffect(() => {
    if (isAuth) {
      toast.success(message);
      navigate('/');
    } else {
      toast.error(message);
    }
  }, [isAuth, navigate, message]);

  React.useEffect(() => {
    axios({
      method: 'get',
      url: `https://vivt.ru/cron/vivt-shop`,
      params: {
        key: key,
      },
    }).then(({ data }) => dispatch(fetchUserLoginVivt(data)));
  }, []);

  return <div className=""></div>;
}

export default AuthorizationVivt;
