import React from 'react';

import UserBaseImg from '../../assets/img/UserBaseImg.png';

function InfoUser({ userInfo }) {
  return (
    <section className="info-user row">
      <div className="col-md-3">
        <div className="info-user__image">
          {userInfo.avatarUrl ? (
            <img src={`https://shop.vivt.ru/api/uploads/${userInfo.avatarUrl}`} alt="" />
          ) : (
            <img src={UserBaseImg} />
          )}
        </div>
      </div>

      <div className="col-md-9">
        <div className="info-user__text">
          <div>
            <div className="info-user__name col-6">{userInfo.userName}</div>
            <div className="info-user__mail">{userInfo.email}</div>
          </div>
          <div>
            <div className="info-user__coin"> количество вивткоинов: {userInfo.coin}</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InfoUser;
