import React from 'react';

import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import imageProducts from '../../assets/img/Rectangle 38.png';

function MyBuy() {
  return (
    <section className="my-buy">
      <h1>Мои покупки</h1>

      <Swiper pagination={true} modules={[Autoplay]} slidesPerView={2} className="mySwiper">
        <SwiperSlide>
          <div className="row">
            <div className="col-3">
              <div className="my-buy__image">
                <img src={imageProducts} alt="" />
              </div>
            </div>
            <div className="col-9">
              <div className="my-buy__text">
                <div className="my-buy__title">
                  <div className="my-buy__name col-4 ">толстовка “vivtВИВТ”</div>
                  <div className="my-buy__price col-3">2200 ₽</div>
                </div>
                <div className="my-buy__desc ">
                  Классическая модель темно-синего цвета с принтом на груди и карманом спереди.
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="row">
            <div className="col-3">
              <div className="my-buy__image">
                <img src={imageProducts} alt="" />
              </div>
            </div>
            <div className="col-9">
              <div className="my-buy__text">
                <div className="my-buy__title">
                  <div className="my-buy__name col-4 ">толстовка “vivtВИВТ”</div>
                  <div className="my-buy__price col-3">2200 ₽</div>
                </div>
                <div className="my-buy__desc ">
                  Классическая модель темно-синего цвета с принтом на груди и карманом спереди.
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="row">
            <div className="col-3">
              <div className="my-buy__image">
                <img src={imageProducts} alt="" />
              </div>
            </div>
            <div className="col-9">
              <div className="my-buy__text">
                <div className="my-buy__title">
                  <div className="my-buy__name col-4 ">толстовка “vivtВИВТ”</div>
                  <div className="my-buy__price col-3">2200 ₽</div>
                </div>
                <div className="my-buy__desc ">
                  Классическая модель темно-синего цвета с принтом на груди и карманом спереди.
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="row">
            <div className="col-3">
              <div className="my-buy__image">
                <img src={imageProducts} alt="" />
              </div>
            </div>
            <div className="col-9">
              <div className="my-buy__text">
                <div className="my-buy__title">
                  <div className="my-buy__name col-4 ">толстовка “vivtВИВТ”</div>
                  <div className="my-buy__price col-3">2200 ₽</div>
                </div>
                <div className="my-buy__desc ">
                  Классическая модель темно-синего цвета с принтом на груди и карманом спереди.
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="row">
            <div className="col-3">
              <div className="my-buy__image">
                <img src={imageProducts} alt="" />
              </div>
            </div>
            <div className="col-9">
              <div className="my-buy__text">
                <div className="my-buy__title">
                  <div className="my-buy__name col-4 ">толстовка “vivtВИВТ”</div>
                  <div className="my-buy__price col-3">2200 ₽</div>
                </div>
                <div className="my-buy__desc ">
                  Классическая модель темно-синего цвета с принтом на груди и карманом спереди.
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
}

export default MyBuy;
