import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { selectIsAuth } from '../redux/slice/userSlice';

import LoginPanel from '../components/Authentication/LoginPanel';
import RegisterPanel from '../components/Authentication/RegisterPanel';

function Authentication() {
  const isAuth = useSelector(selectIsAuth);
  const { message } = useSelector((state) => state.message);
  useSelector((state) => state.auth.message);

  const [visibility, setVisibility] = React.useState(true);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuth) {
      message && toast.success(message);
      navigate('/');
    } else {
      toast.error(message);
    }
  }, [isAuth, navigate, message]);

  return (
    <section className="Authorization">
      <div className="container" id="container">
        <LoginPanel status={visibility} />
        <RegisterPanel status={visibility} />
        <div className="overlay-container">
          <div className="overlay">
            <div
              className={
                visibility
                  ? 'overlay-panel--isActive overlay-panel overlay-right'
                  : ' overlay-panel overlay-right'
              }>
              <h1>Добро пожаловать!</h1>
              <p>Чтобы начать покупки, нужно зарегистрироваться</p>
              <button className="ghost" onClick={() => setVisibility(!visibility)}>
                зарегистрироваться
              </button>
            </div>
            <div
              className={
                !visibility
                  ? 'overlay-panel--isActive overlay-panel overlay-right'
                  : ' overlay-panel overlay-right'
              }>
              <h1>Привет, друг!</h1>
              <p>Введите свои личные данные и начните покупки с нами</p>
              <button className="ghost" onClick={() => setVisibility(!visibility)}>
                Войти
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Authentication;
