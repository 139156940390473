import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchGetAllOrder } from '../../redux/slice/orderSlice';

import { Link } from 'react-router-dom';

function Menu() {
  const dispatch = useDispatch();
  const { newOrder } = useSelector((state) => state.order);
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchGetAllOrder());
  }, [dispatch]);

  const itemSetting = [
    // { name: 'Создать скиду', link: './get-sale' },
    // { name: 'Управление баннером', link: './banner-management' },
    // { name: 'Управление аккаунтами', link: './account-management' },
    { name: 'Управление фильтрами', link: './management-filter' },
    { name: 'Управление коллекциями', link: './management-collections' },
  ];

  return (
    <div className="slidebar">
      <ul>
        <li className="slidebar__list">
          <i className="bi bi-house"></i>
          <span>
            <Link to="./">Главная</Link>
          </span>
        </li>
        <li className="slidebar__list">
          <i className="bi bi-plus-square"></i>
          <span>
            <Link to="get-product">Добавить товар</Link>
          </span>
        </li>
        <li onClick={() => setIsVisible(!isVisible)} className="slidebar__list">
          <div>
            <i className="bi bi-gear-wide"></i>
            <span>Настройка магазина</span>
            <label className="badge badge-light-primary">{itemSetting.length}</label>
            <i className={`bi bi-caret-right-fill ${isVisible ? 'active' : ''}`}></i>
            {isVisible && (
              <div>
                <ul>
                  {itemSetting.map((item, index) => (
                    <li className="slidebar__hide-item" key={index}>
                      <Link className="" to={item.link}>
                        {' '}
                        {item.name}
                      </Link>{' '}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </li>
        <li className="slidebar__list">
          <i className="bi bi-receipt"></i>
          <span>
            <Link to="get-all-order">
              Заказы <label className="badge badge-light-primary">{newOrder}</label>
            </Link>
          </span>
        </li>
      </ul>
    </div>
  );
}

export default Menu;
