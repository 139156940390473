import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setMessage } from './messageSlice';
import axios from '../../utils/axios';

export const fetchUserLogin = createAsyncThunk('auth/login', async (params, thunkAPI) => {
  try {
    const { data } = await axios.post('auth/login', params);

    thunkAPI.dispatch(setMessage(data.message));
    if (data.accessToken) {
      window.localStorage.setItem('token', data.accessToken);
    }
    return data;
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const fetchUserReg = createAsyncThunk('auth/reg', async (params, thunkAPI) => {
  try {
    const { data } = await axios.post('auth/registration', params);

    thunkAPI.dispatch(setMessage(data.message));
    if (data.accessToken) {
      window.localStorage.setItem('token', data.accessToken);
    }
    return data;
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const fetchUserLoginVivt = createAsyncThunk('auth/loginVivt', async (params, thunkAPI) => {
  try {
    const { data } = await axios.post('auth/loginVivt', params);

    if (data.accessToken) {
      window.localStorage.setItem('token', data.accessToken);
    }

    thunkAPI.dispatch(setMessage(data.message));

    return data;
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const fetchAuthMe = createAsyncThunk('auth/refresh', async (thunkAPI) => {
  try {
    const { data } = await axios.get('/auth/refresh');

    if (data.accessToken) {
      window.localStorage.setItem('token', data.accessToken);
    }

    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue();
  }
});

const initialState = {
  data: null,
  status: 'loading',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      axios.post('/auth/logout');
      window.localStorage.removeItem('token');
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    // Login =================================================================
    builder.addCase(fetchUserLogin.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.data = action.payload;
    });
    builder.addCase(fetchUserLogin.rejected, (state) => {
      state.status = 'error';
      state.data = null;
    });

    // Register =================================================================
    builder.addCase(fetchUserReg.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.data = action.payload;
    });
    builder.addCase(fetchUserReg.rejected, (state, action) => {
      state.status = 'err';
      state.data = null;
    });

    // Refresh =================================================================
    builder.addCase(fetchAuthMe.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.data = action.payload;
    });
    builder.addCase(fetchAuthMe.rejected, (state, action) => {
      state.status = 'error';
      state.data = null;
    });

    // LoginVivt =================================================================
    builder.addCase(fetchUserLoginVivt.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.data = action.payload;
    });
    builder.addCase(fetchUserLoginVivt.rejected, (state, action) => {
      state.status = 'error';
      state.data = null;
    });
  },
});

export const selectIsAuth = (state) => Boolean(state.auth.data);
export const authReducer = authSlice.reducer;
export const { logout } = authSlice.actions;
